import React, { Component } from 'react';
import { withFirebase } from '../components/Firebase/FirebaseContext';
import { withTrainings } from '../BuisenessLogic/TrainingsContext';
import Layout from '../components/layout';
import styled from 'styled-components';
import { withPrefix } from 'gatsby';

class Contacts extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <div className="container">
          <br />
          <br />
          <h3>Групові шикування при їзді по шосе</h3>
          <p>
            Їзда по шосе групою — один з найбільш популярних, цікавих і безпечних способів
            переміщення відкритим шосе на велосипеді. Під час їзди в групі велосипедисти більшу
            частину часу їдуть «на колесі» у своїх товаришів, прикриваючись від зустрічного вітру й
            заощаджуючи сили, регулярно змінюють один одного, щоби підтримувати рівний темп.
          </p>
          <img
            width="100%"
            src="https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/sigma%20(3).jpg?alt=media&token=fed83ccf-1910-4a2d-ae17-cb9beeb8d8a6"
            alt=""
            className="img-fluid"
          />
          <p>
            Існує декілька основних групових шикувань, які використовують велосипедисти по всьому
            світу. Нижче описано чотири найголовніші. Розуміючи й попрактикувавши кожне з них, ви не
            пропадете на тренуванні в будь-якій точці земної кулі.
          </p>

          <h3>Навіщо потрібна їзда змінами?</h3>
          <p>
            Як ми вже казали, їзда змінами дозволяє суттєво заощаджувати сили й долати великі
            відстані за одне тренування з меншими витратами часу. Кожен учасник групи по черзі
            займає позицію в голові групи, робить внесок у спільну роботу, після чого здає зміну за
            правилами і йде в кінець групи відпочивати й чекати на наступну зміну.
          </p>
          <video
            style={{ height: '100%', width: '100%' }}
            id="bgvid"
            loop={true}
            muted={true}
            autoPlay={true}
            playsinline={true}
            poster={withPrefix('main-image.jpg')}
            controls={false}
          >
            <source src={withPrefix('pairs.mp4')} type="video/mp4" />
          </video>
          <p>
            Таким чином відбувається постійна ротація учасників, і кожний докидає свою лепту в
            спільну справу боротьби з опором повітря. Особливо це актуально при їзді проти сильного
            вітру, коли навіть дуже сильні велосипедисти швидко втомлюються, крутячи педалі
            самотужки.
          </p>

          <h3>Їзда командою</h3>

          <p>
            Найпростіше шикування, яке можна побачити майже на будь-якому груповому тренуванні або
            гонці. Більшість відривів на гонках використовує або «команду», або «вертушку» (про яку
            йдеться далі).
          </p>
          <img width="100%" src={withPrefix('1l.gif')} alt="" className="img-fluid" />

          <p>
            Якщо описувати це шикування просто, велосипедисти їдуть стрункою колоною, строго на
            колесі один в одного. Людина, заступаючи на зміну, певний час бореться з потоком
            повітря, підтримуючи темп, заданий іншими учасниками групи, після чого, зазвичай у міру
            накопичення втоми в м'язах, здає зміну наступному учаснику й перешиковується у хвіст
            колони. Наступний за ним в колоні велосипедист займає його місце, і все повторюється по
            колу.
          </p>
          <img width="100%" src={withPrefix('comand.jpg')} alt="" className="img-fluid" />

          <p>
            Таке шикування можна більш-менш ефективно використовувати при їзді групами від 2 до
            приблизно 15 людей. За більшої кількості група часто рветься на окремі команди.
          </p>

          <p>
            Командою можна їздити майже на будь-яких швидкостях — від найнижчих до гранично високих,
            проте на практиці при спокійній їзді частіше використовують інше шикування — «їзду
            парами», а на граничних швидкостях часто буває ефективнішим закрутити «вертушку».
          </p>

          <h3>Їзда парами</h3>

          <p>
            Їзда парами — найчастіше шикування, яке можна зустріти при спокійній їзді великої
            кількості велосипедистів-шосейників. Крім того, більшу частину неквапливих
            багатогодинних тренувань команди й скатані групи велосипедистів їздять саме в такому
            шикуванні.
          </p>
          <img width="100%" src={withPrefix('2.gif')} alt="" className="img-fluid" />

          <p>
            Після того, як перша пара відпрацює свою зміну, вона йде у хвіст колони, поступаючись
            місцем іншим учасникам — і так по колу.
          </p>
          <p>
            Перед здачею зміни лідери сигналізують про це похитуванням ліктями та/або фразою
            «зміна». Лівий лідер пришвидшується сильніше, обганяючи правого, і вони разом
            опускаються максимально притулившись вправо — під узбіччя. Важливо при цьому опускатися
            плавно, щоб не викликати різкий перепад швидкостей між групою і тими, хто опускається.
            Це небезпечно, і сісти назад до групи буде складніше.
          </p>
          <div className="lead">
            Нові лідери мають плавно прийняти зміну — при цьому додавши потужності, щоб компенсувати
            посилений опір повітря й зберегти швидкість. Здебільшого для цього достатньо стежити за
            швидкістю.
          </div>
          <br />
          <img width="100%" src={withPrefix('line.jpg')} alt="" className="img-fluid" />

          <p>
            Тут ми перейдемо до складніших шикувань. Якщо «команду» або «пару» можуть їхати навіть
            відверті новачки, то наступні два шикування вимагають досвіду, скатаності та, імовірно,
            «диригента», який на ходу віддаватиме команди, вирівнюючи стрій.
          </p>

          <h3>Вертушка</h3>
          <p>
            Одне з найкрасивіших шикувань на шосе. Її можна побачити у відривах у професіоналів.
            Командну гонку (team time trial) теж часто їдуть «вертушкою».
          </p>
          <img width="100%" src={withPrefix('3.gif')} alt="" className="img-fluid" />
          <p>
            Їзда вертушкою вимагає солідного досвіду, і крім того, ми б не рекомендували крутити
            вертушку з незнайомими людьми. У такому шикуванні будь-який неправильний рух призводить
            до завалів, часто на високих швидкостях.
          </p>
          <p>Спрощено вертушка виглядає приблизно так:</p>
          <p>
            Ви їдете другим колесом. Людина перед вами здає зміну, плавно йдучи вбік і, трохи
            скидаючи швидкість (зазвичай на 1—2 км/год порівняно зі швидкістю руху вертушки),
            починає відставати. Щойно вона переміститься достатньо, щоб ви могли стати перед нею, не
            підрізаючи її переднє колесо, ви повторюєте її маневр, випускаючи на зміну людину, що
            їде за вами, і теж поступово спускаєтесь у хвіст вертушки.
          </p>
          <p>
            Якоїсь миті ви порівняєтеся з останнім велосипедистом у колоні, що рухається вперед, і
            повинні будете сісти йому на колесо. Важливо виконувати цей маневр плавно, без різких
            розгонів, максимально плавно долучаючись до колони. Після цього ви починаєте поступово
            зсуватися в голову колони.
          </p>
          <img
            width="100%"
            src="https://firebasestorage.googleapis.com/v0/b/volovod-488b2.appspot.com/o/sigma%20(85).jpg?alt=media&token=d95bf69f-0601-4cef-8dba-887248fcdc75"
            alt=""
            className="img-fluid"
          />
          <p>
            Якщо дивитися на вертушку згори, у будь-який момент часу вона схожа на дві колони, що
            рухаються паралельно з трохи різною швидкістю — наприклад, 44 і 42 кілометри на годину.
            У цьому русі є два критичні моменти: момент відходу зі зміни й момент перешикування у
            хвіст тягової колони. Обидві ці навички приходять з досвідом, але в результаті ви
            повинні виробити звичку починати перешиковуватися трохи раніше, ніж людина, яка здала
            вам зміну, звільнить вам місце.
          </p>

          <p className="lead">
            Зазвичай цілком безпечно почати перешикування в той момент, коли передній край покришки
            переднього колеса гонщика, що йде назад, порівняється з вашим заднім ексцентриком.
          </p>
          <p>
            Важливо робити це плавно, без різких рухів, тоді ви станете в голову повільної колони на
            невеликій безпечній відстані від людини, що тепер їхатиме у вас на колесі.
          </p>
          <div className="lead">
            Найпоширеніша помилка у вертушці — спроба людини, що виходить на зміну, прискоритися.
            Ніколи цього не робіть! Вертушка рухається з однією швидкістю. Заступаючи на зміну, ви
            маєте чітко її підтримувати та йти зі зміни, щойно вам звільнять місце в колоні, що
            відстає.
          </div>
          <p>
            Головна ідея полягає в тому, що дві колони, які утворюють вертушку, рухаються з певною
            швидкістю — кожна зі своєю, і ці швидкості мають лишатися сталими. Тягова колона
            рухається на 2—3 км/год швидше.
          </p>
          <p>
            Їзда вертушкою найефективніша при дуже сильному зустрічному вітрі. Щойно група, з якою
            ви катаєтеся опанує це шикування, швидка їзда за складних умов перетвориться на процес,
            що створює величезну кількість драйву! Для комфортної вертушки треба щонайменше п'ятеро
            людей.
          </p>
          <p>
            Зміни у вертушці здають під бічний вітер, щоб колона, що відстає, ефективно прикривала
            тягову колону від вітру й дозволяла по максимуму заощаджувати сили іншим учасникам
            шикування.
          </p>

          <h3>Ешелон</h3>
          <p>Існує ще один різновид вертушки — «Ешелон», або «Вертушка під вітер».</p>
          <p>
            Такі шикування часто можна бачити на гонках при сильних бічних вітрах. По суті, це те
            саме, що вертушка, але повернута до вітру. Зміни здають в той бік, звідки він дує. Таке
            шикування ще складніше, ніж звичайна вертушка, і вимагає великого досвіду їзди в групі,
            проте саме воно дозволяє ефективно переміщуватися групами по 8—12 людей при сильних
            перехресних вітрах.
          </p>
          <p>
            Бажаємо вам опанувати всі основні типи шикувань на шосе й насолодитися кожним
            кілометром, який ви подолаєте при їзді в групі!
          </p>
          <iframe
            width="1115"
            height="630"
            src="https://www.youtube.com/embed/nhisWC0zLoA"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <div className="text-right">
            <br />
            <a href="https://nogibogi.com/gruppovye-postroeniya-pri-ezde-po-shosse/">
              За матеріалами статті Юрія Ганусяка
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const LandingPageWithFirebase = styled(withFirebase(withTrainings(Contacts)))`
  background: #0d181c;
  color: white;
  font-size: 15px;
  h3 {
    color: white;
  }
  .lead {
    background: #e30613;
    padding: 5px 10px;
    margin: 5px 0;
  }
`;

export default () => (
  <Layout>
    <LandingPageWithFirebase />
  </Layout>
);
